$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color4: rgb(197, 179, 88);
$color3: rgb(119, 108, 51);

.Palvelut {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 82vw;
    background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0.05, $color3), color-stop(0.95, $color4));

    padding: 5vmin;
    padding-bottom: 2.5vmin;
    margin-bottom: 20vh;
    border-radius: 20px;
    -webkit-box-shadow: 2px 2px 5px 1px $color2;
    -moz-box-shadow: 2px 2px 5px 1px $color2;
    box-shadow: 2px 2px 5px 1px $color2;
    animation-name: fadeIn;
    animation-duration: 1s;

    #contactLink {
        color: rgb(17, 35, 79);
        text-decoration: underline;
    }

    #contactLink:hover {
        cursor: pointer;
    }

    h2 {
        color: #000;
        font-size: $title;
        margin-bottom: 5vmin;
    }

    p{
        color: #000;
        font-size: $text;
        font-weight: 400;
        margin: 0;
        margin-bottom: 7.5vmin;
    }

    button {

        margin: auto;
        margin-top: 5vmin;
        padding: 5vmin;
        background-color: $color2;
        border: none;
        border-radius: 10px;
        max-width: 75vw;
        -webkit-box-shadow: 2px 2px 5px 1px $color2;
        -moz-box-shadow: 2px 2px 5px 1px $color2;
        box-shadow: 2px 2px 5px 1px $color2;

        p {
            font-size: $text;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: #FFF;
            padding: 0;
            margin: 0;
        } 
    }

    ul {
        list-style-position: inside;
        li {
            font-weight: 400;
            color: #000;
        }
    }

    #palvelutSlotit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 100%;

        #slot {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 100%;

            h3 {
                text-align: center;
                font-size: 2.5vmax;
            }

            p {
                font-size: $text;
                padding-bottom: 8vmin;
            }

            img {
                width: 100px;
                margin-left: auto;
                margin-right: auto;
            }

        }
    }
    
}

@media only screen and (min-width: 1025px) {

    .Palvelut {

        width: 82vw;
        max-width: 1200px;

        h2 {
            font-size: $web-title;
        }
    
        p{
            font-size: $web-text;
            margin-bottom: 2.5vmin;
        }

        button {
            border-radius: 10px;
            padding: 25px;

            p {
                font-size: $web-text;
            }
        }

        ul {
            list-style-position: inside;
            li {
                font-size: $web-text;
                font-weight: 400;
                color: #000;
            }
        }

        #palvelutSlotit {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            max-height: 280px;
    
            #slot {
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 20px;
                }
    
                p {
                    font-size: 16px;
                }

                img {
                    width: 100px;
                    margin-left: auto;
                    margin-right: auto;
                }
    
            }
        }

        #contactLink {
            color: rgb(17, 35, 79);
            text-decoration: underline;
        }

        #contactLink:hover {
            cursor: pointer;
        }
    }
      
}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }