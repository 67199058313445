$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: rgb(197, 179, 88);
$color4: rgb(119, 108, 51);

.Tietosuojaseloste {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 82vw;
    background: rgba(0, 0, 0, 0.85);
    padding: 5vmin;
    border-radius: 20px;
    margin-top: calc(-25vh + 20vmin);
    -webkit-box-shadow: 2px 2px 5px 1px $color2;
    -moz-box-shadow: 2px 2px 5px 1px $color2;
    box-shadow: 2px 2px 5px 1px $color2;
    animation-name: fadeIn;
    animation-duration: 1s;

    p{
        color: #FFF;
        font-size: $text;
        font-weight: 300;
        margin: 0;
    }

    ul {
        list-style-position: inside;
        li {
            font-size: $text;
            font-weight: 300;
            color: #FFF;
        }
    }
    
}

@media only screen and (min-width: 1025px) {

    .Tietosuojaseloste {

        width: 82vw;
        max-width: 1100px;
        margin-top: 0px;
        padding: 30px;

        h2 {
            font-size: $web-title;
        }
    
        p{
            font-size: $web-text;
        }

        ul {
            li {
                font-size: $web-text;
            }
        }
    }
      
}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
