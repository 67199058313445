$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #c5b358;
$color2: #000;
$color3: rgb(197, 179, 88);
$color4: rgb(119, 108, 51);

.Referenssit {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 82vw;
  background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.05, $color3),
    color-stop(0.95, $color4)
  );
  border-radius: 20px;
  padding: 5vmin;
  margin-bottom: 20vh;
  -webkit-box-shadow: 2px 2px 5px 1px $color2;
  -moz-box-shadow: 2px 2px 5px 1px $color2;
  box-shadow: 2px 2px 5px 1px $color2;

  h2 {
    color: #000;
    font-size: $title;
    margin-bottom: 6vmin;
  }

  h4 {
    font-size: 1.9vmax;
    color: #fff;
  }

  ul {
    list-style-position: inside;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;

    li {
      padding-bottom: 10px;
      font-size: $text;
    }
  }

  p {
    font-size: $text;
  }

  #ReferenssitContainer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0.5rem;
  }

  #Referenssit1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.67);
    border: 2px solid $color2;
    padding: 10px;
    border-radius: 20px;
    margin: auto;

    p {
      padding: 2px;
      margin: 15px;
      font-size: 1.9vmax;
      font-weight: 300;
      color: #fff;
    }

    hr {
      width: 90%;
      border: 1px solid $color1;
    }

    #terveiset {
      padding: 2px;
      margin: 15px;
      font-style: normal;
      margin: 5px 0 0 0;
    }
  }

  #Referenssit2 {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    margin-top: auto;
    margin-bottom: auto;

    img {
      width: 50%;
      height: auto;
      margin: auto;
      margin-bottom: 0.5rem;
    }

    #person {
      margin: 10px auto 15px auto;
      margin-bottom: 15px;
      min-width: 180px;
      min-height: 180px;
      width: 50vmin;
      height: 50vmin;
      border-radius: 500px;
      background: $color2;
      padding: 0.3vmin;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .Referenssit {
    width: 82vw;
    max-width: 1200px;

    h2 {
      font-size: $web-title;
      margin-bottom: 4vmin;
    }

    h4 {
      font-size: unset;
    }

    p {
      font-size: $web-text;
    }

    ul {
      list-style-position: inside;
      margin-bottom: 20px;
      li {
        font-size: $web-text;
        font-weight: 400;
        color: #000;
      }
    }

    #ReferenssitContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 0;
    }

    #Referenssit1 {
      display: flex;
      flex-direction: column;
      width: calc(100% - 360px - 5vmin);
      background-color: rgba(0, 0, 0, 0.67);
      border: 0.3vmin solid $color2;
      padding: 20px;
      border-radius: 20px;
      margin: auto;

      p {
        color: #fff;
        padding: 2px;
        margin: 10px;
        font-size: 16px;
        font-weight: 300;
      }

      hr {
        width: 100%;
        border: 1px solid $color1;
      }

      #terveiset {
        font-size: 16px;
        font-style: normal;
      }
    }

    #Referenssit2 {
      display: flex;
      flex-direction: column;
      pointer-events: none;
      margin-left: 5vmin;
      margin-top: auto;
      margin-bottom: auto;

      img {
        width: 30vmin;
        height: auto;
        max-width: 180px;
        padding: 0.5vmin;
        margin: auto;
      }

      #person {
        width: 30vmin;
        height: 30vmin;
        max-width: 300px;
        max-height: 300px;
        border-radius: 500px;
        background: $color2;
        padding: 0.3vmin;
      }
    }
  }
}
