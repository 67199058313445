html {
  background-color: #000;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
}

body:after {
  content: '';
  position: fixed; /* stretch a fixed position to the whole screen */
  top: 0;
  height: 100vh; /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  z-index: -1; /* needed to keep in the background */
  background: url(media/background-maiqqi-mobile.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: grayscale(85%);
  -webkit-filter: grayscale(85%);
}

@media only screen and (min-width: 1025px) {
  body:after {
    content: '';
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1; /* needed to keep in the background */
    background: url(media/background-maiqqi-web.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    filter: grayscale(85%);
    -webkit-filter: grayscale(85%);
  }
}
