$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: #776C33;

.Evasteet {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 1;
    bottom: 0;
    margin: 0;
    width: 90%;
    background: rgba(0, 0, 0, 0.85);
    padding: 5%;
    -webkit-box-shadow: 2px 2px 5px 1px $color2;
    -moz-box-shadow: 2px 2px 5px 1px $color2;
    box-shadow: 2px 2px 5px 1px $color2;
    animation-name: fadeInUp;
    animation-duration: 1s;

    p{
        color: #FFF;
        font-size: $text;
        font-weight: 300;
        margin: 0;
        margin-top: auto;
        margin-bottom: auto;
    }

    button {

        margin: auto;
        margin-left: 5vmin;
        padding: 5vmin;
        background-color: $color1;
        border: none;
        border-radius: 10px;

        p {
            font-size: $text;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: #FFF;
            padding: 0;
            margin: 0;
        } 
    }
    
}

@-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

@media only screen and (min-width: 1025px) {

.Evasteet {

    width: 50vw;
    max-width: 768px;
    padding: 25px;
    border-radius: 0 20px 0 0;

    p {
        font-size: 18px; 
    }

    button {

        margin: auto;
        margin-left: 25px;
        padding: 25px;

        p {
            font-size: 20px;
        }
    }
}

}