$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: #776C33;

.App {
  // width: 100vw;
  text-align: center;
  padding-top: 25vh;
}

.splashScreen {
  display: flex;
  margin: auto;
  width: 43vmin;
  height: 43vmin;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  border: 2px solid $color1;
  border-radius: 500px;
  padding: 2%;
  position: relative;
  top: calc((100vh - 50vmin) / 2);
  animation-name: fadeInSplash;
  animation-duration: 2s;
  opacity: 0;

  img {
    margin: auto;
    width: 30vmin;
    height: 30vmin;
  }
}

.NavBar {
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 15vmin;
  background-color: #000;
  border-bottom: 2px solid $color1;
  animation-name: fadeInDown;
  animation-duration: 1s;

  button {
    margin: 0;
    padding: 0;
  }

  img {
    margin: auto;
    width: 55vmin;
    height: 7.915vmin;
  }

  #chevron {
    font-size: 8vmin;
    color: $color1;
    margin: auto 3.7vmin;
    transition-duration: 0.4s;
  }

  #chevronButton {
    outline: 0px;
  }
}

a {
  color: #4E8DE2;
}

.copyright {
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.8vmax;
  padding: 2.5vmin;
  margin-top: 25vh;

  p {
    padding: 0;
    padding-left: 2.5vmin;
    color: #FFF;
    margin-top: auto;
    margin-bottom: auto;
  }

  a {
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

button {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb;
  text-rendering: auto;
  color: #FFF;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  margin: 0em;
  padding: 1px 7px 2px;
  border: none;
  border-image: initial;
}

button:hover {
  cursor: pointer;
}

.NavBarWeb {
  width: 44%;
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  margin-left: 28%;
  background-color: #000;
  border-bottom: 2px solid $color1;
  border-right: 2px solid $color1;
  animation-name: fadeInDown;
  animation-duration: 1s;

  button:focus {
    outline: 0 !important;
  }
  button:active {
    outline: 0 !important;
  }
  
  button:hover {
    color: $color1;
  }

  button {
    width: 100px;
    height: 100px;
    border: 2px solid $color1;
    border-radius: 10px;
    margin-top: 45px;
    z-index: 100;
    left: -50px;
    position: absolute;
    background-color: #000;

    p {
      font-size: 16pt;
    }
  }

  #menuButtonOpen {
    z-index: 2;
    background-color: $color1;
    color: #FFF;

  }

  #menuButtonOpen:hover {
    color: #FFF;
  }

  img {
    width: 390.296px;
    height: 56.172px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}


@media only screen and (min-width: 1025px) {
  
.App {
  padding-top: 200px;
}

.copyright {
  font-size: 18px;
  padding: 18px 0px;
}

}

@-webkit-keyframes fadeInSplash {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInSplash {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeInSplash {
  -webkit-animation-name: fadeInSplash;
  animation-name: fadeInSplash;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}