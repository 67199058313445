$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: #776C33;

.Menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    width: 100%;
    margin-top: calc((15vmin + 2px) - 25vh);
    background-color:#000;
    animation-name: fadeInDown;
    animation-duration: 0.5s;

    button {

        p {
            font-size: $text;
            font-family: 'Open Sans', sans-serif;
            color: #FFF;      
        }   

    }

    button:focus {
        outline: 0 !important;
      }
      button:active {
        outline: 0 !important;
      }

    .socialMedia {
        display: flex;
        flex-direction: row;
        z-index: 1;
        width: 100%;
        background-color:#000;

        button {
            width: 50%;
            padding-bottom: 5px;
            padding-top: 5px;
        }

        #fb {
            background-color: #3b5998;
            border: none;
            margin: 5px 10px 20px 20px;
        }

        #ig {
            border: none;
            margin: 5px 20px 20px 10px;
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        }
    }
    
}

@media only screen and (min-width: 1025px) {

    .Menu {
        width: 44%;
        display: flex;
        position: fixed;
        // position: -webkit-sticky;
        z-index: 1;
        top: 98px;
        left: 28%;
        margin-top: 0px;
        border-right: solid 2px rgba(0, 0, 0, 0.85);
    
        p{
            font-size: $web-text;
        }

        button {

            width: 100%;
            height: 70px;
            padding: 10px;

            p {
                font-size: $web-text;
            }
        }

        button:hover {
            p {
                color: $color1;
            }
        }
    }

    #shorterMenu {
        max-height: 85px;
    }

    .socialMedia {
        display: none !important;
    }
      
}

@-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }