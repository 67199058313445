$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color4: rgb(197, 179, 88);
$color3: rgb(119, 108, 51);

.Yhteydenottopyynto {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 82vw;
    background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0.05, $color3), color-stop(0.95, $color4));
    border-radius: 20px;
    padding: 5vmin;
    margin-bottom: 20vh;
    -webkit-box-shadow: 2px 2px 5px 1px $color2;
    -moz-box-shadow: 2px 2px 5px 1px $color2;
    box-shadow: 2px 2px 5px 1px $color2;

    h2 {
        color: #000;
        font-size: $title;
        margin-bottom: 6vmin;
    }

    p {
        font-size: $text;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: $color2;
        padding: 0;
        margin: 0;
    } 


    button {

        margin: auto;
        margin-top: 3vmin;
        padding: 3vmin;
        background-color: $color2;
        border-radius: 10px;
        border: none;
        max-width: 75vw;

        p {
            color: #FFF;
        }


    }

    .contactForm {
        padding: 15px;
 
        p {
            padding: 15px;
        }

        label {
            display: block;
            font-size: $text;
            margin-top: 15px;
            margin-bottom: 2px;
        }

        input {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            font-size: $text;
            padding: 10px;
            border: 1px solid #000;
            border-radius: 4px;
        }

        input[type="submit" i] {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: #FFF;
            margin: auto;
            margin-top: 15px;
            padding: 3vmin;
            background-color: $color2;
            border-radius: 10px;
            border: none;
            width: 50%;
            max-width: 50%;
        }

        textarea {
            width: 90%;
            font-size: $text;
            padding: 10px;
            border: 1px solid #000;
            border-radius: 4px;
            resize: none;
        }

        #error {
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
            width: 80%;
            color: #FFF;
            background-color: $color2;
            border: 2px solid #d31e1e;
            border-radius: 10px;     
            -webkit-box-shadow: 2px 2px 5px 1px $color2;
            -moz-box-shadow: 2px 2px 5px 1px $color2;
            box-shadow: 2px 2px 5px 1px $color2;
        }
    }
    
}

@media only screen and (min-width: 1025px) {

    .Yhteydenottopyynto {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 82vw;
        max-width: 1000px;
        background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0.05, $color3), color-stop(0.95, $color4));
        border-radius: 20px;
        padding: 5vmin;
        margin-bottom: 20vh;
        -webkit-box-shadow: 2px 2px 5px 1px $color2;
        -moz-box-shadow: 2px 2px 5px 1px $color2;
        box-shadow: 2px 2px 5px 1px $color2;   
    
        h2 {
            color: #000;
            font-size: $web-title;
            margin-bottom: 6vmin;
        }
    
        p {
            font-size: $web-text;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: $color2;
            padding: 0;
            margin: 0;
        } 
    
        .contactForm {
            padding: 15px;
    
            p {
                padding: 15px;
            }

            label {
                display: block;
                font-size: $web-text;
                margin-top: 15px;
                margin-bottom: 2px;
            }
    
            input {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
                font-size: 16px;
                padding: 10px;
                border: 1px solid #000;
                border-radius: 4px;
            }

            input[type="submit" i] {
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                color: #FFF;
                margin: auto;
                margin-top: 15px;
                padding: 15px;
                background-color: $color2;
                border-radius: 10px;
                border: none;
                width: 20%;
                max-width: 20%;
            }

            input[type="submit" i]:hover {
                cursor: pointer;
            }
    
            textarea {
                width: 50%;
                font-size: 16px;
                padding: 10px;
                border: 1px solid #000;
                border-radius: 4px;
                resize: none;
            }

            #error {
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
                width: 40%;
                color: #FFF;
                background-color: $color2;
                border: 2px solid #d31e1e;
                border-radius: 10px;
                -webkit-box-shadow: 2px 2px 5px 1px $color2;
                -moz-box-shadow: 2px 2px 5px 1px $color2;
                box-shadow: 2px 2px 5px 1px $color2;
            }
        }
        
    }

}