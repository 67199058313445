$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: #776C33;

.Landing {
    display: flex;
    flex-direction: column;
    width: 86vw;
    max-width: 1440px;
    background: none;
    padding: 0;
    margin: auto;
    margin-bottom: 40vh;

    h2 {
        color: #FFF;
        font-size: $text;
        font-weight: 400;
        margin: 5px 0;
        padding: 3vmin;
        border: 2px solid $color1;
        border-radius: 20px;
        background-color: rgba(0,0,0,0.85);
        -webkit-box-shadow: 2px 2px 5px 1px $color2;
        -moz-box-shadow: 2px 2px 5px 1px $color2;
        box-shadow: 2px 2px 5px 1px $color2;
    }
        
        #grid1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            background: none;
            animation-name: fadeIn;
            animation-duration: 1s;
            }

        #grid2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            background: none;

            #logo {
                width: 75%;
                max-width: 541px;
                margin: auto;
                margin-top: 5px;
                animation-name: fadeIn;
                animation-duration: 1s;
                border: 2px solid $color1;
                border-radius: 20px;
                -webkit-box-shadow: 2px 2px 5px 1px $color2;
                -moz-box-shadow: 2px 2px 5px 1px $color2;
                box-shadow: 2px 2px 5px 1px $color2;
            }

            #smartphoneOn {
                position: absolute;
                width: 13%;
                max-width: 125px;
                margin-left: 23%;
                padding-bottom: 6%;
                z-index: 0;
                animation-name: fadeInUp;
                animation-duration: 1.2s;
            }

            #laptopOn {
                position: absolute;
                width: 60%;
                max-width: 634px;
                margin-left: 18%;
                padding-top: 15%;
                z-index: 0;
                animation-name: fadeInUp;
                animation-duration: 1.2s;
            }

        }

 }



@media only screen and (min-width: 1025px) {

  .Landing {
    display: flex;
    flex-direction: row;
    width: 86vw;
    max-width: 1440px;
    background: none;
    padding: 0;
    margin: auto;
    margin-bottom: 40vh;
    margin-top: 100px;

    h2 {
        color: #FFF;
        font-size: 26px;
        font-weight: 400;
        margin: 10px 50px;
        padding: 30px;
        border: 2px solid $color1;
        border-radius: 20px;
        background-color: rgba(0,0,0,1);
        -webkit-box-shadow: 2px 2px 5px 1px $color2;
        -moz-box-shadow: 2px 2px 5px 1px $color2;
        box-shadow: 2px 2px 5px 1px $color2;
    }
        
        #grid1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            width: 55%;
            height: 500px;
            background: none;
            animation-name: fadeIn;
            animation-duration: 1s;
            }

        #grid2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            width: 45%;
            height: 500px;
            background: none;

            #logo {
                width: unset;
                max-width: 421px;
                max-height: 421px;
                height: 100%;
                margin: auto;
                animation-name: fadeIn;
                animation-duration: 1s;
            }

            #smartphoneOn {
                position: absolute;
                width: 6.5%;
                max-width: 125px;
                margin-left: 10%;
                padding-bottom: 6%;
                z-index: 0;
                animation-name: fadeInUp;
                animation-duration: 1.2s;
            }

            #laptopOn {
                position: absolute;
                width: 33%;
                max-width: 634px;
                margin-left: 6%;
                padding-top: 7%;
                z-index: 0;
                animation-name: fadeInUp;
                animation-duration: 1.2s;
            }

        }

 }

}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }