$text: 2.2vmax;
$title: 2.8vmax;

$web-text: 18px;
$web-title: 24px;

$color1: #C5B358;
$color2: #000;
$color3: rgb(197, 179, 88);
$color4: rgb(119, 108, 51);

.Yhteystiedot {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 82vw;
    background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0.05, $color3), color-stop(0.95, $color4));
    padding: 5vmin;
    border-radius: 20px;
    -webkit-box-shadow: 2px 2px 5px 1px $color2;
    -moz-box-shadow: 2px 2px 5px 1px $color2;
    box-shadow: 2px 2px 5px 1px $color2;

    #contactLink {
        color: rgb(17, 35, 79);
        text-decoration: underline;
    }

    #contactLink:hover {
        cursor: pointer;
    }

    h2 {
        color: #000;
        font-size: $title;
        margin-bottom: 5vmin;
    }

    p{
        color: #000;
        font-size: $text;
        font-weight: 400;
        margin: 0;
    }


    .grid {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        h3 {
            text-align: center;
            font-size: 2.6vmax;
            margin-bottom: 0;
        }

        #gridTekstit {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 75%;

            p {
                padding-top: 10px;
                text-align: center;

                #iconYhteystiedot {
                    margin-right: 15px;
                }

            }

            }

            #gridKuva {
                display: flex;
                flex-direction: column;
                min-width: 180px;
                min-height: 180px;
                width: 45vmin;
                height: 45vmin;
                pointer-events: none;
                background-color: $color2;
                border-radius: 20px;
                margin: auto;
                margin-bottom: 10px;
        
                img {
                    margin: auto;
                    min-width: 180px;
                    min-height: 180px;
                    width: 45vmin;
                    height: 45vmin;
                    border-radius: 20px;
                }

            }

    }
    
}

@media only screen and (min-width: 1025px) {

    .Yhteystiedot {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 600px;
        background: -moz-linear-gradient(center bottom, $color3 5%, $color4 95%);
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0.05, $color3), color-stop(0.95, $color4));
        padding: 5vmin;
        border-radius: 20px;

        #contactLink {
            color: rgb(17, 35, 79);
            text-decoration: underline;
        }

        #contactLink:hover {
            cursor: pointer;
        }

        h2 {
            color: #000;
            font-size: $web-title;
            color: $color2;
            margin-bottom: 5vmin;
        }
    
        p{
            font-size: $web-text;
            color: $color2;
            font-size: 400;
            margin: 0;
        }   
    
        .grid {
            display: flex;
            flex-direction: row;
            justify-self: center;
            align-items: flex-start;
    
            h3 {
                text-align: left;
                font-size: $web-title;
            }
    
            #gridTekstit {
                display: flex;
                flex-direction: column;
                margin: auto;
                width: 50%;
    
                    h3 {
                        margin-top: 0;
                    }

                                p {
                                    padding-top: 15px;
                                    text-align: left;
                    
                                    #iconYhteystiedot {
                                        margin-right: 15px;
                                    }
                    
                                }
    
                }       

                #gridKuva {
                    display: flex;
                    flex-direction: column;
                    width: 260px;
                    height: 260px;
                    pointer-events: none;
                    background-color: $color2;
                    border-radius: 20px;
                    margin: auto;
            
                    img {
                        margin: auto;
                        width: 260px;
                        height: 260px;
                        border-radius: 20px;
                    }
                }
        }
        
    }
 
}